import React, { useEffect, useState } from "react";
import CommonTable from "../Common/CommonTable";
import { Spinner } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import CommonTd from "../../Tender/Common/CommonTd";
import { getPurchaseOrderCsvFile, poOrderListApi } from "../purchaseApi";
import { errorsMessage, formatDate } from "../../constant";
import { FaFilePdf } from "react-icons/fa";
import BasicPagination from "../../StoreDepartment/commonComponents/Pagination";
import { getTenderUser } from "../../redux/api";

function POListingPage() {
  const [loading, setLoading] = useState(false);
  const [poOrderList, setPoOrderList] = useState([]);
  const userProfile = JSON.parse(localStorage.getItem("profile"));
  const user_id = userProfile?.response?.userDetails?.id;
  const department_id = userProfile?.response?.userDetails?.department_id;
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState("");
  const { state } = useLocation();
  const [filters, setFilters] = useState({
    startDate: state ? state?.startDate : null,
    endDate: state ? state?.endDate : null,
  });
  const [purchaseUser, setPurchaseUser] = useState([]);
  const navigate = useNavigate();

  const tableHeading = [
    {
      name: "Sr No",
      width: "2rem",
    },
    {
      name: "Railway Po No.",
      width: "10rem",
    },
    {
      name: "Purchase Order No",
      width: "10rem",
    },
    {
      name: "Approval State",
      width: "10rem",
    },
    {
      name: "Prepared By",
      width: "10rem",
    },
    {
      name: "OrderDate",
      width: "7rem",
    },
    {
      name: "Party Name",
      width: "15rem",
    },
    {
      name: "PO Completion %",
      width: "10rem",
    },
    {
      name: "Final Po Status (Open/Closed)",
      width: "10rem",
    },
    {
      name: "Action",
      width: "4rem",
    },
  ];

  async function downloadCsv() {
    try {
      const res = await getPurchaseOrderCsvFile({ ...filters, user_id });
      const fileUrl = res?.data?.response?.purchaseCSVList?.file_url;

      if (fileUrl) {
        const link = document.createElement("a");
        link.href = fileUrl;
        link.download = "PO_Listing.csv"; // Set the default file name
        document.body.appendChild(link);
        link.click(); // Simulate a click to start the download
        document.body.removeChild(link);
      } else {
        console.error("No file URL found in the response");
      }
    } catch (error) {
      errorsMessage(error?.response?.status);
    }
  }

  function getTenderUserList() {
    getTenderUser({
      user_id: user_id,
      department_id,
    })
      .then((res) => {
        setPurchaseUser(res?.data?.response?.TenderAuthorisedList);
      })
      .catch((err) => {});
  }

  useEffect(() => {
    getTenderUserList();
  }, []);

  function poOrderData() {
    setLoading(true);
    poOrderListApi({ ...filters, user_id, page: page })
      .then((res) => {
        setPoOrderList(res?.data?.response?.PurchaseOrderList?.data);
        setPageCount(res?.data?.response?.PurchaseOrderList?.last_page);
      })
      .catch((err) => {
        errorsMessage(err?.response?.status);
      })
      .finally(() => {
        setLoading(false);
      });
  }
  function handlePaginateChange(event, value) {
    setPage(value);
  }

  function editData(element) {
    navigate("/purchase-order/po-form", { state: { editData: element } });
  }
  useEffect(() => {
    poOrderData();
  }, [page, filters]);

  useEffect(() => {
    setPage(1);
  }, [filters]);

  return (
    <div className="page-wrapper">
      <div className="page-content-tab responsive-tab">
        <div className="container-fluid">
          <div className="row mt-3 ">
            <div className="col-sm-2">
              <h4 className="page-title">Purchase Order</h4>
            </div>
            <div className="col-sm-7 text-end">
              <button
                className="btn btn-primary text-align-end"
                onClick={downloadCsv}
              >
                Download CSV
              </button>
            </div>

            <div className="col-sm-3 text-end pe-5">
              <select
                type="text"
                id="prepared_by"
                className="form-control "
                name="prepared_by"
                onChange={(e) =>
                  setFilters({ ...filters, prepared_by: e.target.value })
                }
              >
                <option value="" className="text-secondary" hidden disabled>
                  Prepared By
                </option>
                <option value="">All</option>
                {purchaseUser?.map((user) => (
                  <option value={user?.id} key={user?.id}>
                    {user?.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="row mt-3 mb-2">
            <div className="col-sm-12 text-end ">
              <div className="row">
                <div className="col-sm-3">
                  <input
                    type="text"
                    className="form-control"
                    name="railway_po_no"
                    placeholder="Search by Railway Po No."
                    onChange={(e) =>
                      setFilters({ ...filters, railway_po_no: e.target.value })
                    }
                  />
                </div>
                <div className="col-sm-3">
                  <input
                    type="text"
                    className="form-control"
                    name="purchase_order_no"
                    placeholder="Search by Purchase Order No."
                    onChange={(e) =>
                      setFilters({
                        ...filters,
                        purchase_order_no: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="col-sm-3">
                  <input
                    type="text"
                    className="form-control"
                    name="party_name"
                    placeholder="Search by Party Name"
                    onChange={(e) =>
                      setFilters({ ...filters, party_name: e.target.value })
                    }
                  />
                </div>
                <div className="col-sm-3 d-flex pe-5">
                  <input
                    type="date"
                    className="form-control"
                    name="startDate"
                    onChange={(e) =>
                      setFilters({ ...filters, startDate: e.target.value })
                    }
                  />
                  <input
                    type="date"
                    className="form-control"
                    name="endDate"
                    onChange={(e) =>
                      setFilters({ ...filters, endDate: e.target.value })
                    }
                  />
                </div>
              </div>
              {/* <Link to="/purchase-order/po-form">
                <button className="btn btn-danger">Add New</button>
              </Link> */}
            </div>
          </div>

          {loading ? (
            <div
              className="d-flex align-items-center justify-content-center pb-5"
              style={{ height: "70vh" }}
            >
              <Spinner animation="grow" variant="danger" />
              <Spinner animation="grow" variant="danger" />
              <Spinner animation="grow" variant="danger" />
            </div>
          ) : (
            <div className="card">
              <div
                className="card-body "
                style={{ padding: "1rem 0", margin: "0" }}
              >
                <div
                  className="table-responsive table-container-scroll"
                  style={{ overflowX: "auto" }}
                >
                  <table
                    className="table common-table"
                    style={{ tableLayout: "unset" }}
                  >
                    <CommonTable tableHeading={tableHeading}></CommonTable>
                    <tbody>
                      {poOrderList?.map((element, index) => (
                        <tr key={index}>
                          <CommonTd
                            width="1.92rem"
                            value={element.id}
                          ></CommonTd>
                          <CommonTd
                            width="10rem"
                            value={element?.railway_po_no}
                          ></CommonTd>
                          <CommonTd
                            width="10rem"
                            value={element?.purchase_order_no}
                          ></CommonTd>
                          <CommonTd
                            width="10rem"
                            value={element?.status}
                          ></CommonTd>
                          <CommonTd
                            width="10rem"
                            value={element?.prepared_by_name}
                          ></CommonTd>
                          <CommonTd
                            width="7rem"
                            value={formatDate(element?.order_date)}
                          ></CommonTd>
                          <CommonTd
                            width="15rem"
                            value={element?.party_name}
                          ></CommonTd>
                          <CommonTd
                            width="10rem"
                            value={`${element?.po_amount_percentage} %`}
                          ></CommonTd>
                          <CommonTd
                            width="10rem"
                            color={
                              element?.po_status === "Closed" ? "red" : "green"
                            }
                            value={element?.po_status}
                          ></CommonTd>
                          {/* <CommonTd
                            width="10rem"
                            value={element?.total_qty}
                          ></CommonTd> */}

                          {/* <CommonTd
                            width="8rem"
                            value={element?.sub_total}
                          ></CommonTd> */}

                          <td>
                            <p
                              style={{
                                minWidth: "4rem",
                                maxWidth: "4rem",
                                margin: "auto",
                                padding: "auto",
                                cursor: "pointer",
                              }}
                              className="text-center"
                            >
                              <Link to={`/purchase-order-pdf/${element?.id}`}>
                                <FaFilePdf
                                  className="text-dark font-20 m-0 p-0"
                                  style={{ margin: "auto" }}
                                />
                              </Link>
                            </p>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}
          <div className="text-end mt-2">
            <BasicPagination
              handlePaginateChange={handlePaginateChange}
              page={page}
              pageCount={pageCount}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default POListingPage;
