import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Typography,
  TextField,
  InputAdornment,
  Button,
  Box,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import BasicDateRangePicker from "./DateRangePicker";
import { formatDate } from "../../constant";
import styles from "../Inventory/Inventory.module.css";

const InventoryTable = ({
  inventoryLogsData,
  storeDropdown,
  filters,
  handleChange,
  handleDateChange,
  startDate,
  endDate,
}) => {
  return (
    <TableContainer
      component={Paper}
      sx={{
        maxHeight: "48vh",
        boxShadow: "none",
        background: "#FCFCFC",
        minHeight: "48vh",
      }}
    >
      <div
        className="d-flex justify-content-between align-items-center mt-3 mb-3 position-relative"
        style={{ zIndex: "10" }}
      >
        <Typography variant="h5" component="div" sx={{ paddingInline: 2 }}>
          Inventory Logs
        </Typography>
        <div className=" me-4 d-flex align-items-center gap-3 ">
          <select
            style={{ width: "16rem ", height: "2.4rem" }}
            className={`form-select ${styles.inventoryFilterInput}`}
            onChange={handleChange}
            name="storeId"
            value={filters?.storeId}
          >
            <option value="">Select by store</option>
            {storeDropdown?.map((store) => (
              <option value={store?.id} key={store?.id}>
                {store?.store_name}
              </option>
            ))}
          </select>
          <BasicDateRangePicker
            handleChange={handleDateChange}
            startDate={startDate}
            endDate={endDate}
          />
          <TextField
            variant="outlined"
            placeholder="Search by Supplier Name"
            size="small"
            sx={{
              width: "17rem",
              "& .MuiOutlinedInput-root": {
                paddingRight: "0",
              },
              "& .MuiInputBase-input::placeholder": {
                fontSize: "0.875rem", // Adjust the placeholder font size
              },
            }}
            name="search"
            value={filters?.search}
            onChange={handleChange}
            className={styles.inventoryFilterInput}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    sx={{
                      width: "3rem",
                      backgroundColor: "#D03438",
                      borderRadius: "0",
                      "&:hover": {
                        backgroundColor: "#D03438", // Change to your desired hover color
                      },
                    }}
                  >
                    <SearchIcon sx={{ color: "white" }} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>
      </div>

      <Table
        stickyHeader
        aria-label="inventory table"
        sx={{
          borderCollapse: "collapse",
          background: "#FCFCFC",
          border: "1px solid #F1F1F1",
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell style={{ width: "5rem", border: "none" }}>
              S.No.
            </TableCell>
            <TableCell style={{ width: "15rem", border: "none" }}>
              Supplier Name
            </TableCell>
            <TableCell style={{ width: "10rem", border: "none" }}>
              Date{" "}
            </TableCell>
            <TableCell style={{ width: "10rem", border: "none" }}>
              IN/OUT
            </TableCell>
            <TableCell style={{ width: "10rem", border: "none" }}>
              IN/OUT Through{" "}
            </TableCell>
            <TableCell style={{ width: "15rem", border: "none" }}>
              Store
            </TableCell>
            <TableCell style={{ border: "none", width: "10rem" }}>
              Qty/ Unit
            </TableCell>
            <TableCell style={{ border: "none", width: "10rem" }}>
              Rate
            </TableCell>
            <TableCell style={{ border: "none", width: "10rem" }}>
              Total Amount
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {inventoryLogsData?.map((row, index) => (
            <TableRow
              key={index}
              style={{
                border: "none",
                marginBottom: "3rem",
                background: "#FCFCFC",
              }}
            >
              <TableCell
                sx={{ borderRight: "none", borderLeft: "none", height: "2rem" }}
              >
                {index + 1}
              </TableCell>
              <TableCell sx={{ borderRight: "none", borderLeft: "none" }}>
                {row.supplier_name}
              </TableCell>
              <TableCell sx={{ borderRight: "none", borderLeft: "none" }}>
                {formatDate(row.date)}
              </TableCell>
              <TableCell sx={{ borderRight: "none", borderLeft: "none" }}>
                {row.type}
              </TableCell>
              <TableCell sx={{ borderRight: "none", borderLeft: "none" }}>
                {row.through}
              </TableCell>
              <TableCell sx={{ borderRight: "none", borderLeft: "none" }}>
                {row.store_name}
              </TableCell>
              <TableCell sx={{ borderRight: "none", borderLeft: "none" }}>
                {row.total_quantity}
              </TableCell>
              <TableCell sx={{ borderRight: "none", borderLeft: "none" }}>
                {row.rate}
              </TableCell>
              <TableCell sx={{ borderRight: "none", borderLeft: "none" }}>
                {row.item_value}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default InventoryTable;
