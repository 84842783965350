import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { approvePurchaseOrder } from "../../purchaseApi";
import { toast } from "react-toastify";

function PurchaseOrderApproval() {
  const [searchParams] = useSearchParams();
  const purchase_order_id = searchParams.get("purchase_order_id");
  const status = searchParams.get("status");
  const action_by = searchParams.get("action_by");
  const [message, setMessage] = useState("");

  function PurchaseOrderApprovalApi() {
    approvePurchaseOrder({
      purchase_order_id,
      status,
      action_by,
    })
      .then((res) => {
        setMessage(res?.data?.response?.message);
      })
      .catch((err) => toast.error("Something went wrong"));
  }
  useEffect(() => {
    PurchaseOrderApprovalApi();
  }, []);
  return (
    <div className="d-flex align-items-center justify-content-center vh-100 vw-100">
      {message}
    </div>
  );
}

export default PurchaseOrderApproval;
