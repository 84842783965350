import * as Yup from "yup";

export const mainGroupSchema = Yup.object({
  name: Yup.string()
    .required("This field is required")
    .matches(/^[a-zA-Z0-9 ]*$/, "Name cannot contain special characters")
    .required("This field is required")
    .min(3, "Name must be at least 3 characters long"),
});
export const SubGroupSchema = Yup.object({
  name: Yup.string()
    .required("This field is required")
    .matches(/^[a-zA-Z0-9 ]*$/, "Name cannot contain special characters")
    .required("This field is required")
    .min(3, "Name must be at least 3 characters long"),
  main_group_id: Yup.string().required("This field is required"),
});
export const unitMasterSchema = Yup.object({
  unit_name: Yup.string().required("This field is required"),
});
export const countryMasterSchema = Yup.object({
  country_name: Yup.string()
    .required("This field is required")
    .matches(/^[a-zA-Z0-9 ]*$/, "Name cannot contain special characters")
    .required("This field is required")
    .min(3, "Name must be at least 3 characters long"),
});
export const stateMasterSchema = Yup.object({
  state_code: Yup.string().required("This field is required"),
  state_name: Yup.string()
    .required("This field is required")
    .matches(/^[a-zA-Z0-9 ]*$/, "Name cannot contain special characters")
    .required("This field is required")
    .min(3, "Name must be at least 3 characters long"),
  country_id: Yup.string().required("This field is required"),
});
export const cityMasterSchema = Yup.object({
  city_name: Yup.string()
    .required("This field is required")
    .matches(/^[a-zA-Z0-9 ]*$/, "Name cannot contain special characters")
    .required("This field is required")
    .min(3, "Name must be at least 3 characters long"),
  state_id: Yup.string().required("This field is required"),
});
export const storeMasterSchema = Yup.object({
  store_name: Yup.string().required("This field is required"),
  keeper_name: Yup.string()
    .required("This field is required")
    .matches(/^[a-zA-Z0-9 ]*$/, "Name cannot contain special characters")
    .required("This field is required")
    .min(3, "Name must be at least 3 characters long"),
  store_address: Yup.string().required("This field is required"),
  contact_no: Yup.string().required("This field is required"),
  store_type: Yup.string().required("This field is required"),
});
export const itemMasterSchema = Yup.object({
  main_group: Yup.string().required("This field is required"),
  product_name: Yup.string().required("This field is required"),
  unit: Yup.string().required("This field is required"),
  hsn_code: Yup.string()
    .min(6, "HSN code must be at least 6 characters long")
    .max(8, "HSN code cannot be more than 8 characters long"),
  category: Yup.string().required("This field is required"),
  drawing_no: Yup.string().when("category", {
    is: "Railway",
    then: (schema) => schema.required("This field is required"),
    otherwise: (schema) => schema.nullable(),
  }),
});
export const termsAndConditionSchema = Yup.object({
  title: Yup.string()
    .required("This field is required")
    .matches(/^[a-zA-Z0-9 ]*$/, "Title cannot contain special characters")
    .required("This field is required")
    .min(3, "Title must be at least 3 characters long"),
});
export const vehicleMasterSchema = Yup.object({
  transporter_name: Yup.string().required("This field is required"),
});
export const brandMasterSchema = Yup.object({
  brand_name: Yup.string().required("This field is required"),
});
export const materialMasterSchema = Yup.object({
  material_name: Yup.string().required("This field is required"),
});

export const currencyMasterSchema = Yup.object({
  name: Yup.string().required("This field is required"),
  symbol: Yup.string().required("This field is required"),
});
