import React, { useState, useEffect } from "react";
import ".././Material/Material.css";
import { useFormik } from "formik";
import axios from "axios";
import GoodReceiptForm from "./GoodReceiptForm";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import {
  GoodReceiptSchema,
  GoodReceiptSchemaPurchaseOrder,
} from "../../schemas/StoreSchema/GoodReceiptSchema";
import { addGRN } from "../commonComponents/storeApi";
import { errorsMessage } from "../../constant";

function AddGoodsReceipt() {
  const userProfile = JSON.parse(localStorage.getItem("profile"));
  const user_id = userProfile?.response?.userDetails?.id;
  const [validationError, setValidationError] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [grnType, setGrnType] = useState("AgainstPurchaseOrder");
  const navigate = useNavigate();
  const { type } = useParams();

  const initialValuePurchaseOrder = {
    po_id: "",
    invoice_date: "",
    invoice_no: "",
    invoice: "",
    incoming_inspection: "",
    supplier_test_certificate: "",
    comments: "",
    store_name: "",
    railway_po_no: "",
    prepared_by: "",
    party_name: "",
    grnItems: [
      {
        order_item_details_id: "",
        item_id: "",
        item_name: "",
        item_code: "",
        qty: 0,
        rate: 0.0,
        amount: 0.0,
        delivered_qty: 0,
        rejected_qty: 0,
        approved_qty: 0,
        balance_qty: "",
      },
    ],
  };

  const initialValueDelivery = {
    delivery_challan_id: "",
    incoming_inspection: "",
    supplier_test_certificate: "",
    comments: "",
    dispatch_store_name: "",
    delivered_to_store_name: "",
    railway_po_no: "",
    prepared_by: "",
    deliveryChallan_Date: "",
    grnItems: [
      {
        order_item_details_id: "",
        item_id: "",
        item_name: "",
        item_code: "",
        qty: 0,
        rate: 0.0,
        amount: 0.0,
        delivered_qty: 0,
        rejected_qty: 0,
        approved_qty: 0,
        balance_qty: "",
      },
    ],
  };

  
  function addGrnDetail(values) {
    const data = {
      user_id,
      ...values,
      type: grnType,
      grn_receive_type: type === "railway" ? "For Railway" : "For Store",
    };
    setSpinner(true);
    addGRN(data)
      .then((response) => {
        if (response.status === 200) {
          toast.success("GRN Added successfully");
          navigate(-1);
        }
      })
      .catch((error) => {
        //setServerSideValidation(error?.response?.data?.response);
        errorsMessage(error?.response?.status);
      })
      .finally(() => {
        setSpinner(false);
      });
  }

  const {
    values,
    handleSubmit,
    handleChange,
    resetForm,
    errors,
    touched,
    setFieldValue,
    handleBlur,
    setValues,
  } = useFormik({
    initialValues:
      grnType === "AgainstPurchaseOrder"
        ? initialValuePurchaseOrder
        : initialValueDelivery,
    validationSchema:
      grnType === "AgainstPurchaseOrder"
        ? GoodReceiptSchemaPurchaseOrder
        : null,
    onSubmit: (values) => {
      addGrnDetail(values);
    },
  });
  console.log("values", values);

  return (
    <div className="page-wrapper">
      <div className="page-content-tab responsive-tab">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card form_goodsnote">
                <div className="card-body">
                  <div className="page-title-box d-flex justify-content-between align-items-center">
                    <h3>
                      {" "}
                      Add Goods Receipt Note{" "}
                      {type === "railway" ? "For Railway" : "For Store"}{" "}
                    </h3>
                  </div>

                  <GoodReceiptForm
                    handleChange={handleChange}
                    values={values}
                    handleSubmit={handleSubmit}
                    errors={errors}
                    touched={touched}
                    handleBlur={handleBlur}
                    mode="add"
                    setFieldValue={setFieldValue}
                    validationError={validationError}
                    spinner={spinner}
                    state=""
                    setValues={setValues}
                    grnType={grnType}
                    setGrnType={setGrnType}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddGoodsReceipt;
