import React, { useEffect, useState } from "react";
function VendorMaster({
  handleChange,
  values,
  errors,
  touched,
  serverSideValidation,
  tabKey,
  date,
  railwayNo,
}) {
  return (
    <div>
      <h4>VENDOR MASTER</h4>
      <div className="row">
        <div className="col-md-3 mt-3">
          <label htmlFor="" className="form-label fw-bold font-16">
            Request Type{" "}
          </label>
          <select
            className="form-control border-secondary"
            onChange={handleChange}
            value={values?.request_type}
            name="request_type"
          >
            <option value={""}>Select </option>
            <option value={"PurchaseOrder"}>Purchase Order </option>
          </select>
          <p className="text-danger m-0">
            {touched.request_type && errors.request_type
              ? errors.request_type
              : serverSideValidation?.request_type}
          </p>
        </div>
        {/* <div className="col-md-3  mt-3">
          <label htmlFor="" className="form-label fw-bold font-16">
            Order Id{" "}
          </label>
          <input
            className="form-control border-secondary"
            placeholder="Enter Order Id"
            onChange={handleChange}
            value={values?.order_id}
            name="order_id"
          />
          <p className="text-danger m-0">
            {touched.order_id && errors.order_id
              ? errors.order_id
              : serverSideValidation?.order_id}
          </p>
        </div> */}
        <div className="col-md-3  mt-3">
          <label htmlFor="" className="form-label fw-bold font-16">
            Purchase Order No.{" "}
          </label>
          <input
            className="form-control border-secondary"
            placeholder="Enter Purchase Order No."
            onChange={handleChange}
            value={values?.purchase_order_no}
            name="purchase_order_no"
            disabled
          />
          <p className="text-danger m-0">
            {touched.purchase_order_no && errors.purchase_order_no
              ? errors.purchase_order_no
              : serverSideValidation?.purchase_order_no}
          </p>
        </div>
        <div className="col-md-3 mt-3">
          <label htmlFor="" className="form-label fw-bold font-16">
            Order_Date*
          </label>
          <input
            className="form-control border-secondary"
            type="date"
            value={date}
            name="order_date"
            readOnly
          />
          <p className="text-danger m-0">
            {touched.order_date && errors.order_date
              ? errors.order_date
              : serverSideValidation?.order_date}
          </p>
        </div>

        <div className="col-md-3 mt-3">
          <label htmlFor="" className="form-label fw-bold font-16">
            Party Type
          </label>
          <select
            className="form-control border-secondary"
            onChange={handleChange}
            value={values?.party_type}
            name="party_type"
          >
            <option value={""}>Select </option>
            <option value={"SundryCreditors"}>Sundry Creditors </option>
          </select>
          <p className="text-danger m-0">
            {touched.party_type && errors.party_type
              ? errors.party_type
              : serverSideValidation?.party_type}
          </p>
        </div>
        {tabKey !== "Non-Tender" && (
          <div className="row">
            <div className="col-md-3 mt-3">
              <label htmlFor="" className="form-label fw-bold font-16">
                Inspection Agency
              </label>
              <select
                className="form-control border-secondary"
                onChange={handleChange}
                value={values?.inspection_agency}
                name="inspection_agency"
              >
                <option value={""}>Select</option>
                <option value={"M/SRites"}>M/S Rites </option>
                <option value={"Consignee"}>Consignee </option>
                <option value={"M/SIntertek"}>M/S Intertek </option>
                <option value={"BureauVeritas"}>Bureau Veritas </option>
                <option value={"M/STUV"}>M/S TUV </option>
              </select>
              <p className="text-danger m-0">
                {touched.inspection_agency && errors.inspection_agency
                  ? errors.inspection_agency
                  : serverSideValidation?.inspection_agency}
              </p>
            </div>
            <div className="col-md-3 mt-3">
              <label htmlFor="" className="form-label fw-bold font-16">
                Railway PO No
              </label>
              <input
                className="form-control border-secondary"
                placeholder="Enter Railway No."
                value={railwayNo}
                name="railway_po_no"
                readOnly
              />
              <p className="text-danger m-0">
                {touched.railway_po_no && errors.railway_po_no
                  ? errors.railway_po_no
                  : serverSideValidation?.railway_po_no}
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default VendorMaster;
