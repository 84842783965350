import React, { useEffect, useState, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import debounce from "lodash/debounce";
import { poOrderListApi } from "../../Purchase/purchaseApi";
import { errorsMessage } from "../../constant";
import GoodsReciptFormDeliveryChallan from "./GoodsReciptFormDeliveryChallan";
import GoodsReciptFormPurchseOrder from "./GoodsReciptFormPurchseOrder";
import { deliveryChallanListApi } from "../commonComponents/storeApi";
import { toast } from "react-toastify";

const GoodReceiptForm = ({
  handleChange,
  values,
  handleSubmit,
  errors,
  touched,
  handleBlur,
  mode,
  setFieldValue,
  validationError,
  spinner,
  state,
  setValues,
  grnType,
  setGrnType,
}) => {
  const userProfile = JSON.parse(localStorage.getItem("profile"));
  const user_id = userProfile?.response?.userDetails?.id;
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [dropdownList, SetDropDownList] = useState([]);
  const navigate = useNavigate();

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    const name = event.target.name;
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64 = reader.result;
        setFieldValue(name, base64);
      };
    }
  };

  const handleItemChange = (e, index) => {
    const { value, name } = e.target;
    const numericValue = parseFloat(value) || 0;

    let valueCopy = { ...values };
    let itemsCopy = [...valueCopy.grnItems];

    let updatedItem = { ...itemsCopy[index] };

    switch (name) {
      case "delivered_qty":
        if (numericValue > updatedItem.balance_qty) {
          toast.error(
            "Delivered quantity cannot be more than the Balance quantity."
          );
          return;
        }
        updatedItem.delivered_qty = numericValue;
        break;

      case "rejected_qty":
        if (numericValue > updatedItem.delivered_qty) {
          toast.error(
            "Rejected quantity cannot be more than delivered quantity."
          );
          return;
        }
        updatedItem.rejected_qty = numericValue;
        break;

      case "approved_qty":
        updatedItem.approved_qty =
          updatedItem.delivered_qty - updatedItem.rejected_qty;
        break;

      default:
        updatedItem[name] = numericValue;
    }

    itemsCopy[index] = updatedItem;
    valueCopy = {
      ...valueCopy,
      grnItems: itemsCopy,
    };
    setValues(valueCopy);
  };

  async function dropDownData(term, grnType) {
    setLoading(true);
    try {
      let res;
      if (grnType !== "AgainstDeliveryChallan") {
        res = await poOrderListApi({
          user_id,
          purchase_order_no: term,
          skip_role_based_search: true,
        });
      } else {
        res = await deliveryChallanListApi({ user_id, search: term });
      }
      if (grnType !== "AgainstDeliveryChallan") {
        SetDropDownList(res?.data?.response?.PurchaseOrderList?.data || []);
      } else {
        SetDropDownList(res?.data?.response?.listDeliveryChallan?.data || []);
      }
    } catch (err) {
      errorsMessage(err?.response?.status || "Unknown error");
    } finally {
      setLoading(false);
    }
  }

  const handlePoOrderData = (selectedOption) => {
    if (!selectedOption) return;
    const itemsObject = {
      item_id: "",
      item_name: "",
      item_code: "",
      qty: 0,
      rate: 0.0,
      amount: 0.0,
      delivered_qty: 0,
      rejected_qty: 0,
      approved_qty: 0,
      balance_qty: "",
      order_item_details_id: "",
      disc_per: "",
      gst_per: "",
    };

    const itemsArray = selectedOption?.item_details?.map((element) => ({
      ...itemsObject,
      item_id: element?.item_id,
      item_name: element?.item_master_details?.product_name,
      item_code: element?.item_code,
      qty: element?.qty,
      rate: element?.rate,
      amount: element?.amount,
      balance_qty: element?.balance_qty,
      order_item_details_id: element?.id,
      disc_per: element?.disc_per,
      gst_per: element?.gst_percent,
    }));
    setValues((prevValues) => ({
      ...prevValues,
      po_id: selectedOption?.id,
      store_name: selectedOption?.store_name,
      railway_po_no: selectedOption?.railway_po_no,
      prepared_by: selectedOption?.prepared_by_name,
      party_name: selectedOption?.party_name,
      grnItems: itemsArray,
    }));
  };

  const handleDeliveryChallanData = (selectedOption) => {
    if (!selectedOption) return;
    const itemsObject = {
      item_id: "",
      item_name: "",
      item_code: "",
      qty: 0,
      rate: 0.0,
      amount: 0.0,
      delivered_qty: 0,
      rejected_qty: 0,
      approved_qty: 0,
      order_item_details_id: "",
      balance_qty: "",
      disc_per: "",
      gst_per: "",
    };

    const itemsArray = selectedOption?.item_details?.map((element) => ({
      ...itemsObject,
      item_id: element?.item_id,
      item_name: element?.item_name,
      item_code: element?.item_code,
      qty: element?.qty,
      rate: element?.rate,
      amount: element?.amount,
      balance_qty: element?.balance_qty,
      order_item_details_id: element?.id,
      disc_per: element?.disc_per,
      gst_per: element?.gst_percent,
    }));
    setValues((prevValues) => ({
      ...prevValues,
      delivery_challan_id: selectedOption?.id,
      dispatch_store_name: selectedOption?.delivered_from,
      delivered_to_store_name: selectedOption?.delivered_to,
      railway_po_no: selectedOption?.purchase_order_ref_no,
      prepared_by: selectedOption?.prepared_by,
      deliveryChallan_Date: selectedOption?.date_of_supply,
      grnItems: itemsArray,
    }));
  };

  const debouncedFetchPoOrderData = useCallback(
    debounce((term, grnType) => {
      dropDownData(term, grnType);
    }, 600),
    [user_id]
  );

  useEffect(() => {
    if (searchTerm) {
      debouncedFetchPoOrderData(searchTerm, grnType);
    }
  }, [searchTerm, debouncedFetchPoOrderData]);

  useEffect(() => {
    if (mode == "edit") {
      if (grnType !== "AgainstDeliveryChallan") {
        dropDownData(state?.data?.grn_data?.document_no, grnType);
        setFieldValue("po_id", values?.po_id);
      } else {
        dropDownData(state?.data?.grn_data?.document_no, grnType);
        setFieldValue("delivery_challan_id", values?.delivery_challan_id);
      }
    }
  }, [state]);

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="row">
          {mode === "edit" ? null : (
            <div className="col-3">
              <label htmlFor="" className="form-label fw-bold font-16">
                Select GRN Type
              </label>
              <select
                className="form-control border-secondary"
                onChange={(e) => setGrnType(e.target.value)}
                value={grnType}
              >
                <option value={"AgainstPurchaseOrder"}>
                  Against Purchase Order
                </option>
                <option value={"AgainstDeliveryChallan"}>
                  Against Delivery Challan
                </option>
              </select>
            </div>
          )}

          {grnType !== "AgainstDeliveryChallan" ? (
            <GoodsReciptFormPurchseOrder
              handleChange={handleChange}
              errors={errors}
              touched={touched}
              handleBlur={handleBlur}
              handlePoOrderData={handlePoOrderData}
              handleFileInputChange={handleFileInputChange}
              validationError={validationError}
              dropdownList={dropdownList}
              loading={loading}
              values={values}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
            />
          ) : (
            <GoodsReciptFormDeliveryChallan
              handleChange={handleChange}
              errors={errors}
              touched={touched}
              handleBlur={handleBlur}
              handleDeliveryChallanData={handleDeliveryChallanData}
              handleFileInputChange={handleFileInputChange}
              validationError={validationError}
              dropdownList={dropdownList}
              loading={loading}
              values={values}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
            />
          )}
        </div>
        <div className="grn-table-container">
          <h2>Items List</h2>
          <table className="grn-table">
            <thead>
              <tr style={{ background: "#f1f5fa" }}>
                <th style={{ width: "4rem" }}>S.No.</th>
                <th>ITEM CODE</th>
                <th style={{ width: "20rem" }}>ITEM NAME</th>
                <th>QTY/UNIT</th>
                <th>RATE</th>
                <th>AMOUNT</th>
                <th>BALANCE QTY</th>
                <th>DELIVERED QTY</th>
                <th>REJECTED QTY</th>
                <th>APPROVED QTY</th>
              </tr>
            </thead>
            <tbody>
              {values?.grnItems?.map((item, index) => (
                <tr key={item.id}>
                  <td>{index + 1}</td>
                  <td>{item.item_code}</td>
                  <td>{item.item_name}</td>
                  <td>
                    {item.qty}/ {"pcs"}
                  </td>
                  <td>{item.rate}</td>
                  <td>{item.amount}</td>
                  <td>{item.balance_qty}</td>
                  <td>
                    <input
                      type="text"
                      value={item.delivered_qty}
                      className="form-control"
                      placeholder="Delivered Qty"
                      name="delivered_qty"
                      onChange={(e) => handleItemChange(e, index)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      value={item.rejected_qty}
                      className="form-control"
                      placeholder="Rejected Qty"
                      name="rejected_qty"
                      onChange={(e) => handleItemChange(e, index)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      value={item.approved_qty}
                      className="form-control"
                      placeholder="Approved Qty"
                      name="approved_qty"
                      onChange={(e) => handleItemChange(e, index)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {spinner ? (
          <div className="">
            <i className="fa fa-spinner fa-spin fa-3x text-danger me-3 mt-3"></i>
            <p>Wait...</p>
          </div>
        ) : (
          <div class="row pt-4">
            <div>
              <button
                type="button"
                class="btn btn-de-danger btn-sm border-danger"
                onClick={() => {
                  navigate(-1);
                }}
              >
                Cancel
              </button>
              <button type="Submit" class="btn btn-danger mx-2">
                {mode == "add" ? "Add" : "Update"}
              </button>
            </div>
          </div>
        )}
      </form>
    </>
  );
};

export default GoodReceiptForm;
