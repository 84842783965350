import React from "react";
import { useParams } from "react-router-dom";
import Select from "react-select";

function GoodsReciptFormPurchseOrder({
  validationError,
  handleChange,
  errors,
  touched,
  handleBlur,
  handlePoOrderData,
  handleFileInputChange,
  dropdownList,
  loading,
  values,
  setSearchTerm,
  searchTerm,
}) {
  const { type } = useParams();
  return (
    <>
      <div className="col-3">
        <label htmlFor="" className="form-label fw-bold font-16">
          PO Number
        </label>
        <Select
          placeholder="Search By PO Number"
          style={{ width: "100%" }}
          className="form-select-control border-secondary add-grn-input"
          options={[
            { id: "", purchase_order_no: "Select an item" },
            ...dropdownList,
          ]}
          isLoading={loading}
          getOptionLabel={(dropdownList) => dropdownList["purchase_order_no"]}
          getOptionValue={(poOrder) => poOrder["id"]}
          value={
            dropdownList?.find((option) => option.id === values.po_id) || {
              id: "",
              purchase_order_no: "Select an item",
            }
          }
          onInputChange={(value) => setSearchTerm(value)}
          onChange={handlePoOrderData}
          theme={(theme) => ({
            ...theme,
            borderRadius: 4,
            colors: {
              ...theme.colors,
              primary25: "lightgrey",
              primary: "lightgrey",
            },
          })}
        />
        <p className="text-danger m-0">
          {touched.po_id && errors.po_id
            ? errors.po_id
            : validationError?.po_id}
        </p>
      </div>
      <div className="col-3">
        <label htmlFor="" className="form-label fw-bold font-16">
          Supplier Name
        </label>
        <div className="input-group me-3 add-grn-input">
          <input
            type="text"
            name="doc_no"
            className="form-control form-control-sm border-secondary"
            placeholder="Enter Supplier Name"
            style={{ fontSize: ".9rem" }}
            value={values?.party_name}
            readOnly
          />
        </div>
      </div>
      <div className="col-3">
        <label htmlFor="" className="form-label fw-bold font-16">
          Railway PO Number
        </label>
        <div className="input-group me-3 add-grn-input">
          <input
            type="text"
            name="date"
            className="form-control form-control-sm border-secondary"
            style={{ fontSize: ".9rem" }}
            value={values?.railway_po_no}
            readOnly
            placeholder="Railway PO Number"
          />
        </div>
      </div>
      <div className="col-3">
        <label htmlFor="" className="form-label fw-bold font-16">
          PO Generated By 
        </label>
        <div className="input-group me-3 add-grn-input">
          <input
            type="text"
            name="grn_no"
            className="form-control form-control-sm border-secondary"
            placeholder="PO Generated By"
            style={{ fontSize: ".9rem" }}
            value={values?.prepared_by}
            readOnly
          />
        </div>
      </div>
      <div className="col-3">
        <label htmlFor="" className="form-label fw-bold font-16">
          {type === "railway" ? "Railway Site" : "Store Name"}
        </label>
        <div className="input-group me-3 add-grn-input">
          <input
            type="text"
            name="order_no"
            className="form-control form-control-sm border-secondary"
            placeholder={`${type === "railway" ? "Railway Site Name" : "Store Name"}`}
            style={{ fontSize: ".9rem" }}
            value={values?.store_name}
            readOnly
          />
        </div>
      </div>
      <div className="col-3">
        <label htmlFor="" className="form-label fw-bold font-16">
          Invoice Date
        </label>
        <div className="input-group me-3 add-grn-input">
          <input
            type="date"
            name="invoice_date"
            className="form-control form-control-sm border-secondary"
            placeholder="Enter Delivery Location"
            style={{ fontSize: ".9rem" }}
            value={values?.invoice_date}
            onChange={handleChange}
          />

          <p className="text-danger m-0">
            {touched.invoice_date && errors.invoice_date
              ? errors.invoice_date
              : validationError?.invoice_date}
          </p>
        </div>
      </div>
      <div className="col-3">
        <label htmlFor="" className="form-label fw-bold font-16">
          Invoice No.
        </label>
        <div className="input-group me-3 add-grn-input">
          <input
            type="text"
            name="invoice_no"
            className="form-control form-control-sm border-secondary"
            placeholder="Enter Invoice No."
            style={{ fontSize: ".9rem" }}
            value={values?.invoice_no}
            onChange={handleChange}
          />
          <p className="text-danger m-0">
            {touched.invoice_no && errors.invoice_no
              ? errors.invoice_no
              : validationError?.invoice_no}
          </p>
        </div>
      </div>
      <div className="col-3">
        <label htmlFor="" className="form-label fw-bold font-16">
          Invoice <span className="text-danger">*</span>
        </label>
        <div className="input-group me-3 add-grn-input">
          <input
            type="file"
            name="invoice"
            className="form-control form-control-sm border-secondary"
            style={{ fontSize: ".9rem" }}
            onChange={(event) => {
              handleFileInputChange(event);
            }}
            accept=".pdf"
          />
          <p className="text-danger m-0">
            {touched.invoice && errors.invoice
              ? errors.invoice
              : validationError?.invoice}
          </p>
        </div>
      </div>
      <div className="col-3">
        <label htmlFor="" className="form-label fw-bold font-16">
          Supplier Test Certificate
        </label>
        <div className="input-group me-3 add-grn-input">
          <input
            type="file"
            name="supplier_test_certificate"
            className="form-control form-control-sm border-secondary"
            style={{ fontSize: ".9rem" }}
            onChange={(event) => {
              handleFileInputChange(event);
            }}
          />
        </div>
      </div>
      <div className="col-3">
        <label htmlFor="" className="form-label fw-bold font-16">
          Incoming Inspection
        </label>
        <div className="input-group me-3 add-grn-input">
          <input
            type="file"
            name="incoming_inspection"
            className="form-control form-control-sm border-secondary"
            style={{ fontSize: ".9rem" }}
            onChange={(event) => {
              handleFileInputChange(event);
            }}
          />
        </div>
      </div>
      <div className="col-10">
        <label htmlFor="" className="form-label fw-bold font-16">
          Comments
        </label>
        <div className="input-group me-3 add-grn-input">
          <textarea
            type="text"
            name="comments"
            className="form-control form-control-sm border-secondary"
            placeholder="Enter Comments"
            style={{ fontSize: ".9rem" }}
            value={values?.comments}
            onChange={handleChange}
          />
        </div>
      </div>
    </>
  );
}

export default GoodsReciptFormPurchseOrder;
